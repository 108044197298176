var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('footer-navigation'), _c('v-sheet', {
    staticClass: "py-18px py-md-24px",
    attrs: {
      "color": "primary"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "ma-n10px ma-md-n14px",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-10px pa-md-14px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt white--text text-center"
  }, [_vm._v("전문가를 위한 사이트를 확인해보세요")])]), _c('v-col', {
    staticClass: "pa-10px pa-md-14px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "footer-guide-button",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "white",
      "href": "https://pro.villeroy-boch.com/",
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v("전문가 포털로")]), _c('div', {
    staticClass: "icon-open"
  })])], 1)], 1)], 1)], 1), _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-head"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }), _c('v-col', {
    staticClass: "ml-auto",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('footer-family-site')], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "footer-body"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "inline-group"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "font-size-14 font-weight-medium mb-12px mb-md-16px grey--text text--darken-4"
  }, [_vm._v("Villeroy & Boch")])]), _vm._l(_vm.items, function (item, index) {
    return [_c('li', {
      key: index,
      class: {
        clear: item.clear
      }
    }, [_c('span', {
      staticClass: "grey--text"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _vm._v(" " + _vm._s(item.text) + " ")])];
  }), _c('li', {
    staticClass: "py-md-0"
  }, [_c('span', {
    staticClass: "footer-account"
  }, [_vm._v("무통장입금 기업은행 960-004190-04-040")])])], 2)])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('footer-sns')], 1)], 1), _c('v-divider', {
    staticClass: "mt-24px mt-md-30px"
  })], 1)], 1), _c('div', {
    staticClass: "footer-foot"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("© Villeroy & Boch Group 2024")])])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }