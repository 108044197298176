var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-visual"
  }, [_c('client-banner-view', _vm._b({
    attrs: {
      "isPc": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var banner = _ref.banner,
          shows = _ref.shows;
        return [shows ? _c('swiper', _vm._b({
          staticClass: "swiper"
        }, 'swiper', {
          options: _vm.options
        }, false), [_vm._l(banner.slides, function (item, index) {
          return [_c('swiper-slide', {
            key: index
          }, [_c('v-card', {
            staticClass: "main-visual__inner",
            attrs: {
              "img": item.image,
              "tile": "",
              "flat": ""
            }
          }, [_c('v-container', [_c('v-sheet', {
            staticClass: "main-visual__text",
            attrs: {
              "color": "white"
            }
          }, [_c('h2', {
            staticClass: "tit font-tertiary font-weight-regular mt-auto mb-12px mb-md-16px"
          }, [_vm._v(" " + _vm._s(item.subject) + " ")]), _c('p', {
            staticClass: "txt txt--sm"
          }, [_vm._v(" " + _vm._s(item.content) + " ")]), _c('v-btn', {
            staticClass: "v-btn--no-hover mt-8px pa-0",
            attrs: {
              "text": "",
              "large": "",
              "ripple": false,
              "to": item.url
            }
          }, [_c('span', {
            staticClass: "txt txt--xs txt--dark font-weight-medium"
          }, [_vm._v(" " + _vm._s(item.btnText) + " ")]), _c('img', {
            staticClass: "ml-8px",
            attrs: {
              "src": "/images/icon/icon-arrow-right.svg",
              "alt": ""
            }
          })])], 1)], 1)], 1)], 1)];
        })], 2) : _vm._e()];
      }
    }])
  }, 'client-banner-view', {
    code: _vm.code__pc
  }, false)), _c('client-banner-view', _vm._b({
    attrs: {
      "isMb": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var banner = _ref2.banner,
          shows = _ref2.shows;
        return [shows ? _c('swiper', _vm._b({
          staticClass: "swiper"
        }, 'swiper', {
          options: _vm.options
        }, false), [_vm._l(banner.slides, function (item, index) {
          return [_c('swiper-slide', {
            key: index
          }, [_c('v-card', {
            staticClass: "main-visual__inner",
            attrs: {
              "img": item.image,
              "tile": "",
              "flat": ""
            }
          }, [_c('v-container', [_c('v-sheet', {
            staticClass: "main-visual__text",
            attrs: {
              "color": "white"
            }
          }, [_c('h2', {
            staticClass: "tit font-tertiary font-weight-regular mt-auto mb-12px mb-md-16px"
          }, [_vm._v(" " + _vm._s(item.subject) + " ")]), _c('p', {
            staticClass: "txt txt--sm"
          }, [_vm._v(" " + _vm._s(item.content) + " ")]), _c('v-btn', {
            staticClass: "v-btn--no-hover mt-8px pa-0",
            attrs: {
              "text": "",
              "large": "",
              "ripple": false,
              "to": item.url
            }
          }, [_c('span', {
            staticClass: "txt txt--xs txt--dark font-weight-medium"
          }, [_vm._v(" " + _vm._s(item.btnText) + " ")]), _c('img', {
            staticClass: "ml-8px",
            attrs: {
              "src": "/images/icon/icon-arrow-right.svg",
              "alt": ""
            }
          })])], 1)], 1)], 1)], 1)];
        })], 2) : _vm._e()];
      }
    }])
  }, 'client-banner-view', {
    code: _vm.code__mb
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }