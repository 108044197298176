<template>
    <v-row class="row--sm">
        <v-col v-for="item in items" :key="item.name" cols="auto">
            <v-btn x-small class="grey darken-3" icon :aria-label="item.name" :href="item.link" target="_blank">
                <v-img width="14" :src="item.icon" alt=""/>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    name: "Instagram",
                    link: "https://www.instagram.com/villeroyboch/",
                    icon: "/images/icon/icon-instagram.svg",
                },
                {
                    name: "Facebook",
                    link: "https://www.facebook.com/VilleroyandBoch",
                    icon: "/images/icon/icon-facebook.svg",
                },
                {
                    name: "Pinterest",
                    link: "https://www.instagram.com/villeroyboch/",
                    icon: "/images/icon/icon-pinterest.svg",
                },
                {
                    name: "TikTok",
                    link: "https://www.tiktok.com/@villeroyboch",
                    icon: "/images/icon/icon-tiktok.svg",
                },
                {
                    name: "Youtube",
                    link: "https://www.youtube.com/VilleroyundBoch",
                    icon: "/images/icon/icon-youtube.svg",
                },
            ],
        };
    },
};
</script>