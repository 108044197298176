<template>
    <v-expand-transition>
        <v-alert v-show="shows" tile width="100%" dismissible class="top-banner">
            <component v-bind:is="linkAttrs.is" v-bind="linkAttrs" class="top-banner__link">
                <v-img v-bind="{ src }" contain width="100%" height="100%" :max-height="$vuetify.breakpoint.lgAndUp ? 'none' : 300" class="top-banner__img top-banner__img-mobile" />
                <div v-bind="{ src }" class="top-banner__img top-banner__img-pc" :style="'background-image:url(' + src + ')'" />
            </component>
            <template #close>
                <v-btn absolute right center fab class="top-banner__close" @click="hideBanner(item)">
                    <v-icon size="24" color="grey lighten-1">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-alert>
    </v-expand-transition>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
    props: {
        item: Object,
        isPc: Boolean,
    },
    computed: {
        ...mapState({ disableds: (state) => state.bandBanners.disableds }),
        shows() {
            return !this.disableds.includes(this.item?._id);
        },
        src() {
            return this.isPc ? this.item?.imagePc?.url : this.item?.imageMb?.url;
        },
        linkAttrs() {
            const { href } = this.item || {};
            if (href.includes("://")) return { is: "a", href };
            else return { is: "router-link", to: href };
        },
    },
    methods: {
        ...mapMutations("bandBanners", ["hideItem"]),
        hideBanner(item) {
            this.$cookies.set(item._id, false, "1d");
            this.hideItem(item);
        },
    },
};
</script>

<style lang="scss" scoped>
.top-banner {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    // margin: calc(var(--header-body-height) * 2) 0 calc(var(--header-body-height) * -2);
    margin: 0;
    padding: 0;
    box-shadow: none !important;
    overflow: hidden;
    &__link {
        display: block;
        width: 100%;
        line-height: 0;
        background-repeat: repeat-x;
        background-position: center;
    }
    &__img {
        width: 100%;
        height: 100%;
    }
    &__img-pc {
        display: none;
        background-position: center;
        background-repeat: repeat-x;
    }
    ::v-deep {
        .v-alert__content {
            width: 100%;
        }
        .v-image__image {
            background-repeat: repeat-x;
        }
        .top-banner {
            &__close {
                background-color: transparent !important;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .top-banner {
        margin: 0;
        border-bottom: 0;
        &__link {
            max-height: 300px;
        }
    }
}
@media (min-width: 1920px) {
    .top-banner {
        &__img-pc {
            display: block;
            height: 300px;
        }
        &__img-mobile {
            display: none;
        }
    }
}
@media (max-width: 1024px) {
    .top-banner {
        ::v-deep {
            .top-banner {
                &__close {
                    right: 0 !important;
                }
            }
        }
    }
}
</style>
