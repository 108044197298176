<template>
    <main-section>
        <v-container>
            <v-sheet class="section-bg" />
            <client-banner-view isPc v-bind="{ code: code__pc }" v-slot="{ banner, shows }">
                <v-row align="center" class="row--lg" v-if="shows">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img :src="banner.slides[0].image" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">{{ banner.slides[0].subject }}</h2>
                        <p class="txt txt--sm" v-html="banner.slides[0].content"></p>
                        <v-btn large tile :to="banner.slides[0].url" class="white mt-18px mt-md-24px px-20px px-md-26px rounded-xs">
                            <span class="txt txt--xs txt--dark font-weight-medium">{{ banner.slides[0]?.btnText }}</span>
                            <v-img src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </client-banner-view>
            <client-banner-view isMb v-bind="{ code: code__mb }" v-slot="{ banner, shows }">
                <v-row align="center" class="row--lg" v-if="shows">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img :src="banner.slides[0].image" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">{{ banner.slides[0].subject }}</h2>
                        <p class="txt txt--sm" v-html="banner.slides[0].content"></p>
                        <v-btn large tile :to="banner.slides[0].url" class="white mt-18px mt-md-24px px-20px px-md-26px rounded-xs">
                            <span class="txt txt--xs txt--dark font-weight-medium">{{ banner.slides[0]?.btnText }}</span>
                            <v-img src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </client-banner-view>
        </v-container>
    </main-section>
</template>

<script>
import MainSection from "@/components/client/templates/main-section.vue";
import ClientBannerView from "../banner/client-banner-view.vue";
import { BANNERS } from '@/assets/variables';

export default {
    components: {
        MainSection,
        ClientBannerView,
    },
    data: () => ({
        code__pc: BANNERS.MAIN_2.code__pc,
        code__mb: BANNERS.MAIN_2.code__mb,
    }),
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
@media (min-width: 768px) {
    .container {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .section-bg {
        width: calc(100% / 12 * 7);
        height: 100%;
    }
}
</style>
