var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', [_c('v-container', [_c('v-sheet', {
    staticClass: "section-bg"
  }), _c('client-banner-view', _vm._b({
    attrs: {
      "isPc": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _banner$slides$;
        var banner = _ref.banner,
          shows = _ref.shows;
        return [shows ? _c('v-row', {
          staticClass: "row--lg",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "7",
            "order-md": "2"
          }
        }, [_c('v-img', {
          attrs: {
            "src": banner.slides[0].image
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "md": "5"
          }
        }, [_c('h2', {
          staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
        }, [_vm._v(_vm._s(banner.slides[0].subject))]), _c('p', {
          staticClass: "txt txt--sm",
          domProps: {
            "innerHTML": _vm._s(banner.slides[0].content)
          }
        }), _c('v-btn', {
          staticClass: "white mt-18px mt-md-24px px-20px px-md-26px rounded-xs",
          attrs: {
            "large": "",
            "tile": "",
            "to": banner.slides[0].url
          }
        }, [_c('span', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_vm._v(_vm._s((_banner$slides$ = banner.slides[0]) === null || _banner$slides$ === void 0 ? void 0 : _banner$slides$.btnText))]), _c('v-img', {
          staticClass: "ml-8px",
          attrs: {
            "src": "/images/icon/icon-arrow-right.svg"
          }
        })], 1)], 1)], 1) : _vm._e()];
      }
    }])
  }, 'client-banner-view', {
    code: _vm.code__pc
  }, false)), _c('client-banner-view', _vm._b({
    attrs: {
      "isMb": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var _banner$slides$2;
        var banner = _ref2.banner,
          shows = _ref2.shows;
        return [shows ? _c('v-row', {
          staticClass: "row--lg",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "7",
            "order-md": "2"
          }
        }, [_c('v-img', {
          attrs: {
            "src": banner.slides[0].image
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "md": "5"
          }
        }, [_c('h2', {
          staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
        }, [_vm._v(_vm._s(banner.slides[0].subject))]), _c('p', {
          staticClass: "txt txt--sm",
          domProps: {
            "innerHTML": _vm._s(banner.slides[0].content)
          }
        }), _c('v-btn', {
          staticClass: "white mt-18px mt-md-24px px-20px px-md-26px rounded-xs",
          attrs: {
            "large": "",
            "tile": "",
            "to": banner.slides[0].url
          }
        }, [_c('span', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_vm._v(_vm._s((_banner$slides$2 = banner.slides[0]) === null || _banner$slides$2 === void 0 ? void 0 : _banner$slides$2.btnText))]), _c('v-img', {
          staticClass: "ml-8px",
          attrs: {
            "src": "/images/icon/icon-arrow-right.svg"
          }
        })], 1)], 1)], 1) : _vm._e()];
      }
    }])
  }, 'client-banner-view', {
    code: _vm.code__mb
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }