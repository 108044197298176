var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, _vm._b({
    tag: "component"
  }, 'component', Object.assign({}, _vm.$attrs), false), [_vm._t("default", null, null, {
    banner: _vm.banner,
    shows: _vm.shows
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }