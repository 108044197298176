<template>
    <section class="main-visual">
        <client-banner-view isPc v-bind="{ code: code__pc }" v-slot="{ banner, shows }">
            <swiper class="swiper" v-bind="{ options }" v-if="shows">
                <template v-for="(item, index) in banner.slides">
                    <swiper-slide :key="index">
                        <v-card :img="item.image" tile flat class="main-visual__inner">
                            <v-container>
                                <v-sheet color="white" class="main-visual__text">
                                    <h2 class="tit font-tertiary font-weight-regular mt-auto mb-12px mb-md-16px">
                                        {{ item.subject }}
                                    </h2>
                                    <p class="txt txt--sm">
                                        {{ item.content }}
                                    </p>
                                    <v-btn text large :ripple="false" :to="item.url" class="v-btn--no-hover mt-8px pa-0">
                                        <span class="txt txt--xs txt--dark font-weight-medium">
                                            {{ item.btnText }}
                                        </span>
                                        <img src="/images/icon/icon-arrow-right.svg" alt="" class="ml-8px" />
                                    </v-btn>
                                </v-sheet>
                            </v-container>
                        </v-card>
                    </swiper-slide>
                </template>
            </swiper>
        </client-banner-view>
        <client-banner-view isMb v-bind="{ code: code__mb }" v-slot="{ banner, shows }">
            <swiper class="swiper" v-bind="{ options }" v-if="shows">
                <template v-for="(item, index) in banner.slides">
                    <swiper-slide :key="index">
                        <v-card :img="item.image" tile flat class="main-visual__inner">
                            <v-container>
                                <v-sheet color="white" class="main-visual__text">
                                    <h2 class="tit font-tertiary font-weight-regular mt-auto mb-12px mb-md-16px">
                                        {{ item.subject }}
                                    </h2>
                                    <p class="txt txt--sm">
                                        {{ item.content }}
                                    </p>
                                    <v-btn text large :ripple="false" :to="item.url" class="v-btn--no-hover mt-8px pa-0">
                                        <span class="txt txt--xs txt--dark font-weight-medium">
                                            {{ item.btnText }}
                                        </span>
                                        <img src="/images/icon/icon-arrow-right.svg" alt="" class="ml-8px" />
                                    </v-btn>
                                </v-sheet>
                            </v-container>
                        </v-card>
                    </swiper-slide>
                </template>
            </swiper>
        </client-banner-view>
    </section>
</template>

<script>
import { BANNERS } from "@/assets/variables";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientBannerView from "@/components/client/banner/client-banner-view.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientBannerView,
    },
    data: () => ({
        code__pc: BANNERS.MAIN_1.code__pc,
        code__mb: BANNERS.MAIN_1.code__mb,
        options: {
            loop: true,
            autoplay: {
                delay: 10000,
                disableOnInteraction: false,
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.main-visual {
    &__inner {
        margin: 0 auto;
        // max-width: 1920px;
        aspect-ratio: 15/13;
        overflow: hidden;
    }
    .container {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
    &__text {
        max-width: 410px;
        padding: 16px var(--container-gutter) 16px 0;
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -99vw;
            width: 100vw;
            height: 100%;
            background-color: #fff;
            z-index: -1;
        }
    }
}
@media (min-width: 768px) {
    .main-visual {
        &__inner {
            aspect-ratio: 1900/816;
        }
        &__text {
            max-width: initial;
            padding: 32px 50px 0 0;
        }
    }
}
</style>
