<template>
    <component :is="tag" v-bind="{ ...$attrs }">
        <slot v-bind="{ banner, shows }" />
    </component>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";

export default {
    props: {
        tag: { type: String, default: "div" },
        code: { type: String, default: null },
        isPc: { type: Boolean, default: false },
        isMb: { type: Boolean, default: false },
    },
    data: () => ({
        banner: null,
        detect,
    }),
    computed: {
        shows() {
            if (this.isPc) return detect == "pc";
            if (this.isMb) return detect == "mobile";
            return true;
        },
    },
    methods: {
        async init() {
            const { code } = this;
            const { banner } = await api.v1.banners.get({ code });
            this.banner = banner;
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style></style>
