var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-col', {
      key: item.name,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "grey darken-3",
      attrs: {
        "x-small": "",
        "icon": "",
        "aria-label": item.name,
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-img', {
      attrs: {
        "width": "14",
        "src": item.icon,
        "alt": ""
      }
    })], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }